import type { HtmlMetaDescriptor } from '@remix-run/react'
import type { SeoFieldFragment } from '~/generated/graphql'
import TheartlingLogoLargeUrl from '~/static/img/theartling_logo_large.jpg'

export const getSEOFields = (data: any, title?: string | null) => {
  const seoFields: SeoFieldFragment = data?.global?.data?.seoFields

  const result: HtmlMetaDescriptor = {
    title: 'Curated Online Art Gallery | The Artling',
    'og:image': TheartlingLogoLargeUrl,
    charset: 'utf-8',
    viewport: 'width=device-width,initial-scale=1',
  }

  if (title) {
    result['title'] = title
  }

  if (seoFields && seoFields.headTitle) {
    result['title'] = seoFields.headTitle
  }

  if (seoFields && seoFields.metaDescription) {
    result['description'] = seoFields.metaDescription
  }

  if (seoFields && seoFields.ogImage) {
    result['og:image'] = seoFields.ogImage
  }
  return result
}

export default getSEOFields
